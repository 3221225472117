.ion-font-size-medium {
    font-size: medium !important;
}

.ion-font-size-smaller {
    font-size: small !important;
}

.ion-font-weight-bolder {
    font-weight: bolder;
}

.ion-full-width {
    width: 100%;
}

.ion-text-align-center {
    text-align: center;
}

.ion-col-with-icon {
    display: flex;
    justify-content: center;
}

.ion-item-min-content-height {
    height: min-content;
}
